<!--
 * @Description:订单详情无支付按钮 onlineOrderDetailNoPayBut 有关订单页面
 * @Author: zhoucheng
 * @Github:无
 * @Date: 2021-04-01 11:55:42
 * @LastEditors: zhoucheng
-->
<template>
  <div class='mainbody'>
    <van-nav-bar title="订单详情"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <van-row class="main">
      <van-row class="infoBox">
        <van-row class="infoTitle">
          <img :src="require('@/assets/commonPage/orderIcon.png')"
               alt="">
          <span class="wenzi">订单详情</span>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">订单号</van-col>
          <van-col class="details">{{orderList.orderSequence}}</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">车牌号</van-col>
          <van-col class="details">{{orderList.plateNumber}}</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">在停地</van-col>
          <van-col class="details">{{orderList.parkName}}</van-col>
        </van-row>
        <van-row class="infoLine"
                 v-if="parkTypeCode===1">
          <van-col class="name">泊位号</van-col>
          <van-col class="details">{{orderList.parkSpaceNumber}}</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">开始时间</van-col>
          <van-col class="details">{{orderList.entranceTime}}</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">停车时长</van-col>
          <van-col class="details">{{orderList.parkDuration}}</van-col>
        </van-row>
        <van-row class="infoLine"
                 v-if="billRuleCode">
          <van-col class="name">计费规则</van-col>
          <van-col style="color:red">
            <span v-if="billRuleCode===1">半小时1元</span>
            <span v-if="billRuleCode===2">1小时2元</span>
          </van-col>
          <van-col style="color:blue;margin-left: 20px"
                   @click="queryBillingRuleDetail">详情</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">收款单位</van-col>
          <van-col class="details">中交四公局(重庆)城市建设发展有限公司</van-col>
        </van-row>
        <van-row class="infoLine"
                 style="margin-bottom:8px">
          <van-col class="name">应收费用</van-col>
          <van-col class="pay"
                   style="width:200px">
            <span style="color:#19A9FC;font-size:20px;">{{orderList.receivableMoneyAmount}}
            </span>
            <span v-if="showFree===2"> - </span>
            <span v-if="showFree===2"
                  style="color:red;font-size:20px;">{{orderList.receivableMoneyAmount}}</span>
            元
          </van-col>
        </van-row>
      </van-row>
      <van-row class="infoBox">
        <van-row class="infoLine"
                 style="margin-bottom:8px">
          <van-col :span=20
                   class="name1">订单有疑虑？</van-col>
          <van-col :span=4
                   class="pay"
                   style="float:right;color:#0382E9"
                   @click="toOrderComplain">
            点击申诉
          </van-col>
        </van-row>
      </van-row>
    </van-row>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
// import formatDuration from '@/utils/duration'
import parkStopTime from '@/utils/parkStopTime'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      orderList: {}, // 订单详情列表
      showFree: 1, // 计费规则是否免费 2是免费 1是收费
      totalNeedMoney: 0, // 当前订单
      billingRuleDefId: '',
      billRuleCode: 0,
      parkId: '',
      parkTypeCode: 0,
      current: this.$fmtDate(new Date(), 'yyyy-MM-dd hh:mm:ss'),
      ctrl: null
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.getOrderList()
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () {
    clearInterval(this.ctrl)
  }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 获取订单详情列表
    getOrderList () {
      const info = {
        plateNumber: this.$route.query.plateNumber,
        numberPlateColorCode: this.$route.query.numberPlateColorCode
      }
      this.$payForParkCount.queryOnlineOrderBySinglePlateNumber(info).then(res => {
        this.orderList = res.resultEntity
        // this.orderList.parkDuration = formatDuration(this.orderList.parkDuration)
        // 如果无离场时间 需要自己算
        if (!this.orderList.leaveTime) {
          this.ctrl = setInterval(() => {
            this.orderList.parkDuration = parkStopTime(this.orderList.entranceTime, this.$fmtDate(new Date(), 'yyyy-MM-dd hh:mm:ss'))
          }, 1000)
        } else {
          this.orderList.parkDuration = parkStopTime(this.orderList.entranceTime, this.orderList.leaveTime)
        }
        // 应收金额等于=应收-实收
        this.orderList.receivableMoneyAmount =
          Number((this.orderList.receivableMoneyAmount - this.orderList.receivedMoneyAmount) / 100).toFixed(2)
        this.totalNeedMoney = this.orderList.receivableMoneyAmount
        // 订单申诉
        this.moneyAmount = res.resultEntity.receivableMoneyAmount
        this.moneyPay = res.resultEntity.receivedMoneyAmount

        this.orderSequence = res.resultEntity.orderSequence

        this.billingRuleDefId = res.resultEntity.billingRuleDefId
        if (this.billingRuleDefId === 'V7') {
          this.billRuleCode = 1
        } else if (this.billingRuleDefId === '1') {
          this.billRuleCode = 2
        }

        this.parkId = res.resultEntity.parkId
        this.queryParkType(this.parkId)
        this.queryForFree()
      })
    },
    // 查询停车场类型
    queryParkType (val) {
      const info = {
        parkId: val
      }
      this.$commonPage.queryParkInfo(info).then(res => {
        this.parkTypeCode = res.resultEntity.parkTypeCode
      })
    },
    // 跳转订单申诉
    toOrderComplain () {
      this.$router.push({
        name: 'orderComplain',
        query: {
          orderSequence: this.orderSequence,
          moneyAmount: this.moneyAmount,
          moneyPay: this.moneyPay
        }
      })
    },
    // 查询该计费规则是否收费
    queryForFree () {
      const info = {
        billingRuleDefId: this.billingRuleDefId
      }
      this.$payForParking.queryBillingRuleById(info).then(res => {
        // 2是免费 1是收费
        if (res.resultEntity.chargeStatusCode === 2) {
          this.showFree = 2
        } else if (res.resultEntity.chargeStatusCode === 1) {
          this.showFree = 1
        }
      })
    },
    // 查看计费规则详情
    queryBillingRuleDetail () {
      this.$router.push({
        name: 'billRuleDetail',
        query: { billRuleCode: this.billRuleCode }
      })
      // this.$router.push({
      //   name: 'billRuleDetail',
      //   query: { parkId: this.parkId }
      // })
      // this.$router.push({
      //   name: 'billRuleDetail',
      //   query: { billingRuleDefId: this.billingRuleDefId }
      // })
    },
    // 顶部返回按钮
    handleClickTopBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.mainbody {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  .main {
    height: 100px;
    .infoBox {
      margin: 12px 3.2%;
      background-color: #ffffff;
      width: 93.6%;
      border-radius: 5px;
      padding-top: 12px;
      .infoTitle {
        margin: 7.9px 0 12px 12px;
        font-size: 15px;
        color: #333;
        font-weight: 500;
        font-family: PingFang-SC;
        display: flex;
        align-items: center;
        img {
          width: 24px;
          height: 24px;
        }
        .wenzi {
          margin-left: 10px;
        }
      }
      .infoLine {
        height: 33.5px;
        line-height: 33.5px;
        .name {
          display: block;
          width: 80px;
          line-height: 33.5px;
          font-size: 15px;
          color: #909090;
          font-weight: 400;
          margin-left: 12px;
        }
        .name1 {
          display: block;
          width: 100px;
          line-height: 33.5px;
          font-size: 15px;
          color: #909090;
          font-weight: 400;
          margin-left: 12px;
        }
        .details {
          display: block;
          width: calc(100% - 80px - 12px);
          min-height: 33.5px;
          line-height: 33.5px;
          font-size: 15px;
          color: #333333;
        }
        .pay {
          display: block;
          width: 80px;
          line-height: 33.5px;
          font-size: 15px;
          color: #333333;
        }
      }
    }
  }
}
</style>
